import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Link as RemixLink } from '@remix-run/react'
import { LinkProps } from './link'

export type { LinkProps }

export const Link: React.FC<LinkProps> = ({
  className,
  disabled,
  children,
  onClick,
  ...props
}) => {


  const doNothing = React.useCallback(() => {}, [])


  const handleClick=useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
    if (disabled) {
      doNothing()
    } else if (onClick) {
      onClick(e)
    }
  },[disabled, onClick])


  return (
    <RemixLink
      className={clsx(
        {
          'text-primary-600 hover:text-primary-500 dark:text-primary-400 hover:dark:text-primary-300':
            !className && typeof disabled === 'undefined',
          'text-gray-300 dark:text-slate-600': disabled,
          'cursor-pointer': !disabled,
        },
        className,
      )}
      role="link"
      onClick={handleClick}
      {...props}
    >
      {children}
    </RemixLink>
  )
}
