import React from 'react'
// @ts-ignore
import { ClientOnly } from 'remix-utils/client-only'
import { LoadingIndicator } from '~ui/LoadingIndicator'

import { ErrorBox } from '~ui/ErrorBox'
import {
  DocumentPlusIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ClipboardDocumentIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

const icons = {
  add: <DocumentPlusIcon className="w-5 h-5" />,
  edit: <PencilSquareIcon className="w-5 h-5" />,
  delete: <TrashIcon className="w-5 h-5" />,
  copy: <ClipboardDocumentIcon className="w-5 h-5" />,
  ok: <CheckCircleIcon className="w-5 h-5" />,
  cancel: <XMarkIcon className="w-5 h-5" />,
  chevron: <ChevronDownIcon className="w-5 h-5" />,
}

const ReactJsonView = React.lazy(() =>
  import('json-edit-react').then((module) => ({
    default: module.JsonEditor,
  })),
)

export const JsonView: React.FC<{
  json: string | any
  editable?: boolean
  addable?: boolean
  onChange?: (json: string | number | boolean) => void
}> = ({ json, editable, addable, onChange }) => {
  const [theme, setTheme] = React.useState<any | undefined>()

  React.useEffect(() => {
    import('json-edit-react').then((module) => {
      setTheme(module.githubDarkTheme)
    })
  }, [])

  const jsonResult = React.useMemo(() => {
    try {
      if (!json?.length) {
        return { data: null, error: null }
      }
      const res = typeof json === 'string' ? JSON.parse(json) : json
      return { data: res, error: null }
    } catch (err: any) {
      console.log('json error', err)
      return { data: null, error: err.message }
    }
  }, [json])

  if (jsonResult.error) {
    return jsonResult.error?.includes('valid JSON') ? (
      json
    ) : (
      <ErrorBox message={jsonResult.error} className="my-2" />
    )
  }
  if (!jsonResult.data && !addable) {
    return <p>No data</p>
  }

  if (!theme) {
    return <LoadingIndicator />
  }
  return (
    <ClientOnly fallback={<LoadingIndicator />}>
      {() => (
        <React.Suspense fallback={<LoadingIndicator />}>
          <ReactJsonView
            data={jsonResult.data}
            theme={theme}
            viewOnly={!editable}
            showStringQuotes={false}
            showArrayIndices
            icons={icons}
            collapse={4}
            restrictEdit={!editable}
            restrictAdd={!addable}
            onChange={({ newValue }) => {
              onChange ? onChange(newValue) : {}
              return newValue
            }}
          />
        </React.Suspense>
      )}
    </ClientOnly>
  )
}
