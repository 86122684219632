import clsx from 'clsx'

export const Paper: React.FC<
  React.PropsWithChildren<{ rounded?: boolean; className?: string }>
> = ({ rounded = true, className, children }) => {
  return (
    <div
      className={clsx(
        'ring-1 px-4 pt-2.5 pb-3',
        {
          'bg-gray-50 ring-gray-200 dark:bg-slate-800 ring-slate-500': !!className,
          'rounded-md': rounded,
        },
        className,
      )}
    >
      {children}
    </div>
  )
}
