import React, { useCallback, useEffect, useState } from 'react';
// import Datepicker from 'react-tailwindcss-datepicker';
import { useField } from 'remix-validated-form';
import { format, getYear, getMonth } from 'date-fns';
import FormElement from '~ui/FormElement';
import { DateInputProps } from './date-input';

import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";

export type { DateInputProps };

interface SimplifiedDateTimeInputProps extends DateInputProps {
  formatDate?: string;
}

const formatDateForSubmission = (date: Date | null) => {
  if (!date || isNaN(date.getTime())) {
    return '';
  }
  const formattedDate = format(date, 'yyyy-MM-dd');
  return formattedDate;
};

// Define formatDateForDisplay here before using it in the component
const formatDateForDisplay = (
date: Date | null,
formatDate: string = 'yyyy-MM-dd') =>
{
  if (!date || isNaN(date.getTime())) {
    return '';
  }
  return format(date, formatDate);
};

const range = (start: number, end: number) => {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
};

export const DateInput = React.forwardRef<
  HTMLInputElement,
  SimplifiedDateTimeInputProps>(

  (
  {
    name,
    value,
    label,
    required,
    labelBgColor,
    helperText,
    description,
    containerClassName,
    minDate,
    onChange,
    formatDate = 'yyyy-MM-dd', // default format for display
    ...props
  },
  ref) =>
  {
    const { error, validate } = useField(name);
    const [date, setDate] = useState<Date | null>(
      value ? new Date(value) : null
    );
    const [inputValue, setInputValue] = useState<string>(
      date ? formatDateForDisplay(date, formatDate) : ''
    );

    const currentRef = React.useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const input = currentRef.current?.querySelector('input');
      const onFocus = () => {
        input?.select();
      };
      if (input) {
        input.addEventListener('focus', onFocus);
      }
      return () => {
        input?.removeEventListener('focus', onFocus);
      };
    }, []);

    // Synchronize state for Datepicker selection and manual input
    const handleChange = useCallback(
      async (newDate: Date | null) => {
        setDate(newDate);
        setInputValue(newDate ? formatDateForDisplay(newDate, formatDate) : '');

        if (newDate && !isNaN(newDate.getTime())) {
          try {
            await validate();
          } catch (validationError) {
            console.error('Validation failed:', validationError);
          }
        }
      },
      [validate, formatDate]
    );

    const [startDate, setStartDate] = useState(value ? new Date(value) : null);
    const years = range(1960, getYear(new Date()) + 1, 1);
    const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'];


    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        description={description}
        className={containerClassName}
        ref={currentRef}>

        <style>
          {`
          .react-datepicker__month,
          .react-datepicker__month-container,
            .react-datepicker{
              border-radius: 12px;
            }
            .react-datepicker-wrapper{
              width:100%;
            }
            .react-datepicker__month-container{
              padding-top
            }


              .react-datepicker-popper[data-placement^=top],
                     .react-datepicker__triangle {
                    fill: #fff;
                    color: #fff;
                    }
                    .react-datepicker-popper .react-datepicker__triangle {
                    stroke: #aeaeae;
                    }



              .react-datepicker__aria-live {
                position: absolute;
                clip-path: circle(0);
                border: 0;
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                width: 1px;
                white-space: nowrap;
              }
              .react-datepicker__day--keyboard-selected, 
              .react-datepicker__day--selected,
              .react-datepicker__month-text--keyboard-selected,
              .react-datepicker__quarter-text--keyboard-selected, 
              .react-datepicker__year-text--keyboard-selected,
              .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover  {
                    border-radius: 50%;
                    background-color: #0f86e2;
                    color: rgb(255 255 255) !important;
                }
            .react-datepicker{
                background: #fff;
                box-shadow: 1px 1px 5px #eee;
              }
            .react-datepicker__input-container,
            .react-datepicker__view-calendar-icon{
                position: relative;
            }
            .react-datepicker__header {
              background-color: white; 
            }
              select{
                background-image: none
              }

                .react-datepicker__day:hover {
                background-color:#0f86e2 !important;
                }
              .react-datepicker__day-name,
              .react-datepicker__day,
              .react-datepicker__time-name {
                  color: #000;
                  display: inline-block;
                  width: 2rem;
                  line-height: 2rem;
                  text-align: center;
                  margin: 0.166rem;
              }
              .react-datepicker__day--keyboard-selected, 
              .react-datepicker__month-text--keyboard-selected, 
              .react-datepicker__quarter-text--keyboard-selected, 
              .react-datepicker__year-text--keyboard-selected {
                    border-radius: 50%;
                    background-color: #0f86e2;
                    color: rgb(255 255 255);
                }
             
             @media (prefers-color-scheme: dark) {
              .react-datepicker{
                border-color: #374151 !important;
                border-radius: 0.5rem;
                 background: #1e293b;
                
              }

               .react-datepicker-popper .react-datepicker__triangle {
                    fill: #1e293b !important;
                    color: #1e293b !important;
                    }

                    .react-datepicker-popper .react-datepicker__triangle {
                    stroke: #1e293b !important;
                    
                    }
              
              .react-datepicker__day--selected,
              .react-datepicker__day--keyboard-selected, 
              .react-datepicker__month-text--keyboard-selected, 
              .react-datepicker__quarter-text--keyboard-selected, 
              .react-datepicker__year-text--keyboard-selected, .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
                    border-radius: 50%;
                    background-color: #0f86e2 !important;
                    color: rgb(255 255 255);
                }
              .react-datepicker__month-container,
              .react-datepicker__header {
                background: #1e293b;
                color: #e2e8f0; /* light text color */
              }
                .react-datepicker__day:hover {
                background-color:#0f86e2 !important;
                }
              .react-datepicker__current-month,              
              .react-datepicker__day {
                color: #e2e8f0; /* dark:text-slate-300 equivalent */
                background-color: #1e293b
              }

              .react-datepicker__day-name{
                color: #57606E;
                background-color: #1e293b
              }
                .react-datepicker__month {
                  background: #1e293b;
              }
                  select{
                    background: #1e293b;
                    border-color: #374151 !important
                  }
                   
            }
          `}
        </style>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) =>
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center'
            }}>

              <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{
                borderRadius: '0.375rem 0 0 0.375rem',
                borderColor: '#d1d5db',
                borderRight: 'none'
              }}>

                {years.map((option) =>
              <option key={option} value={option}>
                    {option}
                  </option>
              )}
              </select>

              <select
              value={months[getMonth(date)]}
              style={{
                borderRadius: ' 0 0.375rem 0.375rem 0',
                borderColor: '#d1d5db',
                borderLeft: 'none'
              }}
              onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
              }>

                {months.map((option) =>
              <option key={option} value={option}>
                    {option}
                  </option>
              )}
              </select>
            </div>
          }
          selected={startDate}
          ariaRequired={false}
          onChange={(date) => setStartDate(date)}
          placeholderText="MM/DD/YYYY"
          dateFormat="MM/dd/yyyy"
          minDate={minDate}
          className="w-full border-gray-300 focus:border-primary-600 dark:border-gray-500 dark:focus:border-slate-300 bg-white text-gray-900 dark:bg-slate-900 dark:focus:bg-slate-900 dark:text-slate-400 w-full py-2.5 text-sm rounded-md  px-2.5 relative"
          showIcon
          icon={
          <svg
            className="absolute top-[3px] right-[10px] z-[9] text-gray-400 w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor">

              <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />

            </svg>
          } />

        <input
          type="hidden"
          name={name}
          value={startDate ? formatDateForSubmission(startDate) : ''}
          ref={ref} />

      </FormElement>);

  }
);

export default DateInput;