import { NavLink, useMatches, useResolvedPath } from '@remix-run/react'
import { TabProps } from './tab'

export function Tab({ Icon, name, to }: TabProps) {
  const resolved = useResolvedPath(to)
  const matches = useMatches()
  const isActive = matches.find((m) => m.pathname === resolved.pathname)

  return (
    <li className={isActive ? `cursor-default` : `cursor-pointer`}>
      <NavLink
        to={to}
        className={`group w-full gap-x-2 max-w-[12rem] inline-flex items-center justify-around p-4 rounded-t-lg border-b-2 font-semibold ${
          isActive
            ? 'text-primary-800 border-primary-800 dark:text-primary-500 dark:border-primary-500'
            : 'border-transparent text-gray-400 hover:text-gray-600 hover:border-gray-300 dark:text-slate-400 hover:dark:text-slate-200'
        }`}
      >
        {Icon ? (
          <Icon
            className={`w-5 h-5 ${
              isActive
                ? 'text-primary-800 dark:text-primary-500'
                : 'text-gray-600 group-hover:text-gray-500 dark:text-slate-400 dark:group-hover:text-slate-300'
            }`}
          />
        ) : null}
        <p className="flex-1">{name}</p>
      </NavLink>
    </li>
  )
}
