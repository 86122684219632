import clsx from 'clsx'
import { Transition, DisclosurePanel } from '@headlessui/react'
import { AccordionPanelProps } from './accordion-panel'


export type { AccordionPanelProps }

export const AccordionPanel: React.FC<AccordionPanelProps> = ({
  open,
  className,
  children,
}) => {
  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <DisclosurePanel as="dd" className={clsx(/*'pr-12', */className)}>
        {children}
      </DisclosurePanel>
    </Transition>
  )
}
