import React, { useCallback, useRef } from 'react'
import { CameraInputButtonProps } from './camera-input-button'

export const CameraInputButton: React.FC<
  React.PropsWithChildren<CameraInputButtonProps>
> = ({
  uploading,
  className,
  children,
  onChange,
  hasPermission,
  onClose,
  onCameraAccess,
  onCapturePhoto,
  name = '',
  accept = 'image/*',
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const handleCapturePhoto = useCallback(() => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas')
      canvas.width = videoRef.current.videoWidth
      canvas.height = videoRef.current.videoHeight

      const context = canvas.getContext('2d')

      if (context) {
        // Check if context exists
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height)

        canvas.toBlob((blob) => {
          if (blob) {
            // Check if blob exists
            const file = new File([blob], 'captured_image.png', {
              type: 'image/*',
            })

            if (file) {
              onChange(file)
            }
          }
        }, 'image/*')
      }
    }
  }, [videoRef.current])
  return (
    <>
      {['granted'].includes(hasPermission) ? (
        <div className="flex flex-col items-center justify-center gap-2 ">
          {videoRef ? (
            <div className="w-40 h-52 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer overflow-hidden border-2 border-gray-400">
              <video
                ref={videoRef}
                autoPlay
                playsInline
                className="max-w-md rounded-lg shadow-md"
              ></video>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}
