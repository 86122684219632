import clsx from 'clsx'
import React from 'react'
import FormElement from '../FormElement'

type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string
  error?: string
  name: string
  labelBgColor?: string
  helperText?: string
  description?: string
  containerClassName?: string
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      name,
      label,
      helperText,
      description,
      error,
      required,
      labelBgColor,
      containerClassName,
      children,
      ...props
    },
    ref,
  ) => {

    const handlePaste = React.useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const pastedText = event.clipboardData?.getData('text')

      if(props.onChange){
        event.preventDefault()
        event.stopPropagation()
        props.onChange({
          target: {
            value: pastedText,
            type: 'textarea',
            tagName: 'TEXTAREA',
          },
        } as React.ChangeEvent<HTMLTextAreaElement>)
      }
    }, [props.onChange])
    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        description={description}
        className={containerClassName}
      >
        <textarea
          ref={ref}
          rows={4}
          name={name}
          {...props}
          onPaste={handlePaste}
          className={clsx(
            'block w-full rounded-md py-1.5 bg-white text-gray-900 dark:text-slate-400 dark:bg-slate-900 rounded-lg border-1 sm:text-sm sm:leading-6',
            'border-gray-300 appearance-none dark:text-gray dark:border-gray-500',
            'focus:outline-none focus:ring-0 peer focus:border-primary-600 dark:focus:border-slate-500',
            {
              'border-error-500 focus:border-error-500 dark:border-error-500 dark:focus:border-error-600':
                error,
            },
            props.className,
          )}
        />
        {children}
      </FormElement>
    )
  },
)
