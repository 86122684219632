import { DisclosureButton } from '@headlessui/react'
import clsx from 'clsx'
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { AccordionButtonProps } from './accordion-button'

export type { AccordionButtonProps }

export const AccordionButton: React.FC<AccordionButtonProps> = ({
  open,
  checked,
  compact,
  children,
  className,
  onClick,
}) => {
  return (
    <DisclosureButton
      type="button"
      className={clsx(
        'rounded-md flex w-full items-center justify-between text-left',
        { 'px-4 py-3': !compact, 'px-3 py-2': compact },
        'bg-gray-100 text-gray-900',
        'dark:bg-slate-900 dark:text-slate-300',
        className,
      )}
      onClick={onClick}
    >
      <span className="text-base font-medium leading-7">{children}</span>
      <span className="ml-6 flex h-7 items-center">
        {checked ? (
          <CheckCircleIcon
            className={clsx('h-6 w-6 text-success-600')}
            aria-hidden="true"
          />
        ) : (
          <ChevronRightIcon
            className={clsx('h-6 w-6', {
              'rotate-0': !open,
              'rotate-90': open,
            })}
            aria-hidden="true"
          />
        )}
      </span>
    </DisclosureButton>
  )
}
